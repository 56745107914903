var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    [
      _c(
        "b-tabs",
        { attrs: { "content-class": "mt-2" } },
        [
          _c(
            "b-tab",
            { attrs: { title: _vm.$t("LICENSE.TITLE") } },
            [
              _c(
                "b-form-group",
                {
                  attrs: { "label-cols-md": "3", label: _vm.$t("DESCRIPTION") },
                },
                [
                  _c("b-form-input", {
                    staticClass: "w-150",
                    attrs: { trim: "" },
                    model: {
                      value: _vm.prices.description,
                      callback: function ($$v) {
                        _vm.$set(_vm.prices, "description", $$v)
                      },
                      expression: "prices.description",
                    },
                  }),
                ],
                1
              ),
              _c(
                "b-form-group",
                {
                  attrs: {
                    "label-cols-md": "3",
                    label: _vm.$t("LICENSE.CURRENCY"),
                  },
                },
                [
                  _c("b-form-input", {
                    staticClass: "w-150",
                    attrs: { trim: "" },
                    model: {
                      value: _vm.prices.currency,
                      callback: function ($$v) {
                        _vm.$set(_vm.prices, "currency", $$v)
                      },
                      expression: "prices.currency",
                    },
                  }),
                ],
                1
              ),
              _c(
                "b-form-group",
                {
                  attrs: {
                    "label-cols-md": "3",
                    label: _vm.$t("LICENSE.TITLE"),
                  },
                },
                [
                  _c("b-form-input", {
                    staticClass: "w-150",
                    attrs: {
                      trim: "",
                      type: "number",
                      number: _vm.return_number,
                    },
                    model: {
                      value: _vm.prices.license,
                      callback: function ($$v) {
                        _vm.$set(_vm.prices, "license", $$v)
                      },
                      expression: "prices.license",
                    },
                  }),
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.prices.currency) +
                      " " +
                      _vm._s(_vm.$t("PER_MONTH")) +
                      "\n      "
                  ),
                ],
                1
              ),
              _c(
                "b-form-group",
                {
                  attrs: {
                    "label-cols-md": "3",
                    label: _vm.$t("LICENSE.INCLUDED_ACCOUNTS"),
                  },
                },
                [
                  _c("b-form-input", {
                    staticClass: "w-150",
                    attrs: {
                      trim: "",
                      type: "number",
                      number: _vm.return_number,
                    },
                    model: {
                      value: _vm.prices.included_accounts,
                      callback: function ($$v) {
                        _vm.$set(_vm.prices, "included_accounts", $$v)
                      },
                      expression: "prices.included_accounts",
                    },
                  }),
                  _vm._v("\n        " + _vm._s(_vm.$t("EACH")) + "\n      "),
                ],
                1
              ),
              _c(
                "b-form-group",
                {
                  attrs: {
                    "label-cols-md": "3",
                    label: _vm.$t("LICENSE.ACCOUNTS"),
                  },
                },
                [
                  _c("b-form-input", {
                    staticClass: "w-150",
                    attrs: {
                      trim: "",
                      type: "number",
                      number: _vm.return_number,
                    },
                    model: {
                      value: _vm.prices.accounts,
                      callback: function ($$v) {
                        _vm.$set(_vm.prices, "accounts", $$v)
                      },
                      expression: "prices.accounts",
                    },
                  }),
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.prices.currency) +
                      " " +
                      _vm._s(_vm.$t("EACH_PER_MONTH")) +
                      "\n      "
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "b-tab",
            { attrs: { title: _vm.$t("BILLING.AUTHENTICATIONS") } },
            [
              _c(
                "b-form-group",
                {
                  attrs: {
                    "label-cols-md": "3",
                    label: _vm.$t("AUTH_METHODS.freja"),
                  },
                },
                [
                  _c("b-form-input", {
                    staticClass: "w-150",
                    attrs: {
                      trim: "",
                      type: "number",
                      number: _vm.return_number,
                    },
                    model: {
                      value: _vm.prices.login_freja,
                      callback: function ($$v) {
                        _vm.$set(_vm.prices, "login_freja", $$v)
                      },
                      expression: "prices.login_freja",
                    },
                  }),
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.prices.currency) +
                      " " +
                      _vm._s(_vm.$t("EACH_UNIQUE")) +
                      "\n      "
                  ),
                ],
                1
              ),
              _c(
                "b-form-group",
                {
                  attrs: {
                    "label-cols-md": "3",
                    label: _vm.$t("AUTH_METHODS.freja-org"),
                  },
                },
                [
                  _c("b-form-input", {
                    staticClass: "w-150",
                    attrs: {
                      trim: "",
                      type: "number",
                      number: _vm.return_number,
                    },
                    model: {
                      value: _vm.prices.login_freja_org,
                      callback: function ($$v) {
                        _vm.$set(_vm.prices, "login_freja_org", $$v)
                      },
                      expression: "prices.login_freja_org",
                    },
                  }),
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.prices.currency) +
                      " " +
                      _vm._s(_vm.$t("EACH_UNIQUE")) +
                      "\n      "
                  ),
                ],
                1
              ),
              _c(
                "b-form-group",
                {
                  attrs: {
                    "label-cols-md": "3",
                    label: _vm.$t("AUTH_METHODS.bankid-se"),
                  },
                },
                [
                  _c("b-form-input", {
                    staticClass: "w-150",
                    attrs: {
                      trim: "",
                      type: "number",
                      number: _vm.return_number,
                    },
                    model: {
                      value: _vm.prices.login_bankid_se,
                      callback: function ($$v) {
                        _vm.$set(_vm.prices, "login_bankid_se", $$v)
                      },
                      expression: "prices.login_bankid_se",
                    },
                  }),
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.prices.currency) +
                      " " +
                      _vm._s(_vm.$t("EACH")) +
                      "\n      "
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "b-tab",
            { attrs: { title: _vm.$t("BILLING.VERIFICATIONS") } },
            [
              _c(
                "b-form-group",
                {
                  attrs: {
                    "label-cols-md": "3",
                    label: _vm.$t("AUTH_METHODS.freja"),
                  },
                },
                [
                  _c("b-form-input", {
                    staticClass: "w-150",
                    attrs: {
                      trim: "",
                      type: "number",
                      number: _vm.return_number,
                    },
                    model: {
                      value: _vm.prices.verify_freja,
                      callback: function ($$v) {
                        _vm.$set(_vm.prices, "verify_freja", $$v)
                      },
                      expression: "prices.verify_freja",
                    },
                  }),
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.prices.currency) +
                      " " +
                      _vm._s(_vm.$t("EACH_UNIQUE")) +
                      "\n      "
                  ),
                ],
                1
              ),
              _c(
                "b-form-group",
                {
                  attrs: {
                    "label-cols-md": "3",
                    label: _vm.$t("AUTH_METHODS.bankid-se"),
                  },
                },
                [
                  _c("b-form-input", {
                    staticClass: "w-150",
                    attrs: {
                      trim: "",
                      type: "number",
                      number: _vm.return_number,
                    },
                    model: {
                      value: _vm.prices.verify_bankid_se,
                      callback: function ($$v) {
                        _vm.$set(_vm.prices, "verify_bankid_se", $$v)
                      },
                      expression: "prices.verify_bankid_se",
                    },
                  }),
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.prices.currency) +
                      " " +
                      _vm._s(_vm.$t("EACH")) +
                      "\n      "
                  ),
                ],
                1
              ),
              _c(
                "b-form-group",
                {
                  attrs: {
                    "label-cols-md": "3",
                    label: _vm.$t("AUTH_METHODS.sms"),
                  },
                },
                [
                  _c("b-form-input", {
                    staticClass: "w-150",
                    attrs: {
                      trim: "",
                      type: "number",
                      number: _vm.return_number,
                    },
                    model: {
                      value: _vm.prices.verify_sms,
                      callback: function ($$v) {
                        _vm.$set(_vm.prices, "verify_sms", $$v)
                      },
                      expression: "prices.verify_sms",
                    },
                  }),
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.prices.currency) +
                      " " +
                      _vm._s(_vm.$t("EACH")) +
                      "\n      "
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <section>
    <b-tabs content-class="mt-2">
      <b-tab :title="$t('LICENSE.TITLE')">

        <b-form-group label-cols-md="3" :label="$t('DESCRIPTION')">
          <b-form-input trim
            v-model="prices.description"
            class="w-150"
          ></b-form-input>
        </b-form-group>

        <b-form-group label-cols-md="3" :label="$t('LICENSE.CURRENCY')">
          <b-form-input trim v-model="prices.currency" class="w-150"></b-form-input>
        </b-form-group>

        <b-form-group label-cols-md="3" :label="$t('LICENSE.TITLE')">
          <b-form-input trim
            v-model="prices.license"
            type="number"
            class="w-150"
            :number="return_number"
          ></b-form-input>
          {{ prices.currency }} {{ $t("PER_MONTH") }}
        </b-form-group>

        <b-form-group
          label-cols-md="3"
          :label="$t('LICENSE.INCLUDED_ACCOUNTS')"
        >
          <b-form-input trim
            v-model="prices.included_accounts"
            type="number"
            class="w-150"
            :number="return_number"
          ></b-form-input>
          {{ $t("EACH") }}
        </b-form-group>

        <b-form-group label-cols-md="3" :label="$t('LICENSE.ACCOUNTS')">
          <b-form-input trim
            v-model="prices.accounts"
            type="number"
            class="w-150"
            :number="return_number"
          ></b-form-input>
          {{ prices.currency }} {{ $t("EACH_PER_MONTH") }}
        </b-form-group>
      </b-tab>
      <b-tab :title="$t('BILLING.AUTHENTICATIONS')">
        <b-form-group label-cols-md="3" :label="$t('AUTH_METHODS.freja')">
          <b-form-input trim
            v-model="prices.login_freja"
            type="number"
            class="w-150"
            :number="return_number"
          ></b-form-input>
          {{ prices.currency }} {{ $t("EACH_UNIQUE") }}
        </b-form-group>

        <b-form-group label-cols-md="3" :label="$t('AUTH_METHODS.freja-org')">
          <b-form-input trim
            v-model="prices.login_freja_org"
            type="number"
            class="w-150"
            :number="return_number"
          ></b-form-input>
          {{ prices.currency }} {{ $t("EACH_UNIQUE") }}
        </b-form-group>

        <b-form-group label-cols-md="3" :label="$t('AUTH_METHODS.bankid-se')">
          <b-form-input trim
            v-model="prices.login_bankid_se"
            type="number"
            class="w-150"
            :number="return_number"
          ></b-form-input>
          {{ prices.currency }} {{ $t("EACH") }}
        </b-form-group>
      </b-tab>

      <b-tab :title="$t('BILLING.VERIFICATIONS')">
        <b-form-group label-cols-md="3" :label="$t('AUTH_METHODS.freja')">
          <b-form-input trim
            v-model="prices.verify_freja"
            type="number"
            class="w-150"
            :number="return_number"
          ></b-form-input>
          {{ prices.currency }} {{ $t("EACH_UNIQUE") }}
        </b-form-group>

        <b-form-group label-cols-md="3" :label="$t('AUTH_METHODS.bankid-se')">
          <b-form-input trim
            v-model="prices.verify_bankid_se"
            type="number"
            class="w-150"
            :number="return_number"
          ></b-form-input>
          {{ prices.currency }} {{ $t("EACH") }}
        </b-form-group>

        <b-form-group label-cols-md="3" :label="$t('AUTH_METHODS.sms')">
          <b-form-input trim
            v-model="prices.verify_sms"
            type="number"
            class="w-150"
            :number="return_number"
          ></b-form-input>
          {{ prices.currency }} {{ $t("EACH") }}
        </b-form-group>
      </b-tab>
    </b-tabs>
  </section>
</template>
<script>
export default {
  props: ["prices", "partner"],
  data() {
    return {
      return_number: true
    };
  },
  methods: {
  },
  computed: {
  },
  mounted(){    
  }
};
</script>
<style></style>

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "span",
    [
      _c(
        "span",
        {
          directives: [
            {
              name: "b-toggle",
              rawName: "v-b-toggle.administration-add-node",
              modifiers: { "administration-add-node": true },
            },
          ],
        },
        [_vm._t("default")],
        2
      ),
      _c(
        "b-sidebar",
        {
          attrs: {
            "sidebar-class": "big_sidebar",
            id: "administration-add-node",
            backdrop: "",
            lazy: "",
            right: "",
            "no-slide": "",
            shadow: "",
          },
          on: { shown: _vm.showSidebar },
          model: {
            value: _vm.show,
            callback: function ($$v) {
              _vm.show = $$v
            },
            expression: "show",
          },
        },
        [
          _vm.license
            ? _c(
                "div",
                { staticClass: "px-3 py-2 create-message" },
                [
                  _c(
                    "b-overlay",
                    { attrs: { show: _vm.loading, rounded: "sm" } },
                    [
                      _c(
                        "b-alert",
                        { attrs: { show: "" } },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.$t("NOTE.ONLY_AS_NODE")) +
                              "\n          "
                          ),
                          _c("br"),
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.$t("NOTE.ORGANISATION_AS_SERVICE")) +
                              "\n          "
                          ),
                          _c(
                            "router-link",
                            {
                              attrs: {
                                to: { name: "Administration.Organisations" },
                              },
                            },
                            [_vm._v(_vm._s(_vm.$t("CLICK_HERE")))]
                          ),
                        ],
                        1
                      ),
                      _vm.step == 1
                        ? _c(
                            "div",
                            [
                              _c(
                                "b-form-group",
                                {
                                  staticClass: "mt-2",
                                  attrs: {
                                    "label-cols-md": "4",
                                    label: _vm.$t("ORGANISATION.TITLE"),
                                  },
                                },
                                [
                                  _c(
                                    "b-form-select",
                                    {
                                      attrs: {
                                        name: "add_node_select_organisation",
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "first",
                                            fn: function () {
                                              return [
                                                _c(
                                                  "b-form-select-option",
                                                  { attrs: { value: "0" } },
                                                  [
                                                    _vm._v(
                                                      _vm._s(_vm.$t("NEW"))
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            },
                                            proxy: true,
                                          },
                                        ],
                                        null,
                                        false,
                                        2558481109
                                      ),
                                      model: {
                                        value: _vm.payload.organisation_id,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.payload,
                                            "organisation_id",
                                            $$v
                                          )
                                        },
                                        expression: "payload.organisation_id",
                                      },
                                    },
                                    _vm._l(
                                      _vm.organisations,
                                      function (organisation) {
                                        return _c(
                                          "b-form-select-option",
                                          {
                                            key: organisation.id,
                                            attrs: { value: organisation.id },
                                          },
                                          [_vm._v(_vm._s(organisation.name))]
                                        )
                                      }
                                    ),
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: _vm.payload.organisation_id == "0",
                                      expression:
                                        "payload.organisation_id == '0'",
                                    },
                                  ],
                                },
                                [
                                  _c(
                                    "b-form-group",
                                    {
                                      staticClass: "mt-2",
                                      attrs: {
                                        "label-cols-md": "4",
                                        label: _vm.$t("NAME"),
                                      },
                                    },
                                    [
                                      _c("b-form-input", {
                                        attrs: {
                                          trim: "",
                                          type: "text",
                                          name: "payload.organisation.name",
                                        },
                                        model: {
                                          value: _vm.payload.organisation.name,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.payload.organisation,
                                              "name",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "payload.organisation.name",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-form-group",
                                    {
                                      attrs: {
                                        "label-cols-md": "4",
                                        label: _vm.$t("LANGUAGE.TITLE"),
                                      },
                                    },
                                    [
                                      _c(
                                        "b-form-select",
                                        {
                                          attrs: {
                                            name: "add_node_select_language",
                                          },
                                          model: {
                                            value:
                                              _vm.payload.organisation.language,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.payload.organisation,
                                                "language",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "payload.organisation.language",
                                          },
                                        },
                                        _vm._l(
                                          _vm.sefos_locales,
                                          function (value, key) {
                                            return _c(
                                              "b-form-select-option",
                                              {
                                                key: key,
                                                attrs: { value: key },
                                              },
                                              [
                                                _vm._v(
                                                  "\n                  " +
                                                    _vm._s(
                                                      _vm.$t("LANGUAGE." + key)
                                                    ) +
                                                    "\n                "
                                                ),
                                              ]
                                            )
                                          }
                                        ),
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-form-group",
                                    {
                                      staticClass: "mt-2",
                                      attrs: {
                                        "label-cols-md": "4",
                                        label: _vm.$t(
                                          "ORGANISATION.UNIQUE_IDENTIFIER"
                                        ),
                                      },
                                    },
                                    [
                                      _c("b-form-input", {
                                        attrs: {
                                          trim: "",
                                          type: "text",
                                          name: "payload.organisation.unique_identifier",
                                          state: _vm.organisationRegistered,
                                        },
                                        model: {
                                          value:
                                            _vm.payload.organisation
                                              .unique_identifier,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.payload.organisation,
                                              "unique_identifier",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "payload.organisation.unique_identifier",
                                        },
                                      }),
                                      _c(
                                        "b-form-invalid-feedback",
                                        {
                                          attrs: {
                                            id: "input-unique-identifier-feedback",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n                " +
                                              _vm._s(
                                                _vm.$t(
                                                  "ERROR.ALREADY_REGISTERED"
                                                )
                                              ) +
                                              "\n              "
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c("hr", { staticClass: "p-0 m-0 mt-4 mb-2" }),
                              _c(
                                "b-button",
                                {
                                  staticClass: "mt-2 btn-fill",
                                  attrs: {
                                    variant: "primary",
                                    disabled: _vm.disabledStep2,
                                  },
                                  on: { click: _vm.gotoStep2 },
                                },
                                [_vm._v(_vm._s(_vm.$t("NEXT")))]
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.step == 2
                        ? _c(
                            "div",
                            [
                              _c(
                                "b-form-group",
                                {
                                  attrs: {
                                    "label-cols-md": "4",
                                    label: _vm.$t("DESCRIPTION"),
                                  },
                                },
                                [
                                  _c("b-form-input", {
                                    attrs: {
                                      trim: "",
                                      type: "text",
                                      name: "payload.name",
                                    },
                                    model: {
                                      value: _vm.payload.name,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.payload, "name", $$v)
                                      },
                                      expression: "payload.name",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "b-form-group",
                                {
                                  attrs: {
                                    "label-cols-md": "4",
                                    label: _vm.$t("LICENSE.MULTITENANCY"),
                                  },
                                },
                                [
                                  _c("b-form-select", {
                                    attrs: { options: _vm.multitenancy },
                                    model: {
                                      value: _vm.payload.multitenancy,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.payload,
                                          "multitenancy",
                                          $$v
                                        )
                                      },
                                      expression: "payload.multitenancy",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _vm.license.type == "PA"
                                ? _c(
                                    "b-form-group",
                                    {
                                      attrs: {
                                        "label-cols-md": "4",
                                        label: _vm.$t("TYPE"),
                                      },
                                    },
                                    [
                                      _c("b-form-select", {
                                        attrs: { options: _vm.node_type },
                                        model: {
                                          value: _vm.payload.type,
                                          callback: function ($$v) {
                                            _vm.$set(_vm.payload, "type", $$v)
                                          },
                                          expression: "payload.type",
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _c("hr", { staticClass: "p-0 m-0 mt-4 mb-2" }),
                              _c(
                                "b-button",
                                {
                                  staticClass: "mt-2",
                                  on: { click: _vm.gotoStep1 },
                                },
                                [_vm._v(_vm._s(_vm.$t("BACK")))]
                              ),
                              _c(
                                "b-button",
                                {
                                  staticClass: "mt-2 btn-fill",
                                  attrs: {
                                    variant: "primary",
                                    disabled: _vm.disabledStep3,
                                  },
                                  on: { click: _vm.gotoStep3 },
                                },
                                [_vm._v(_vm._s(_vm.$t("NEXT")))]
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.step == 3
                        ? _c(
                            "div",
                            [
                              _c("div", { staticClass: "info-header" }, [
                                _vm._v(_vm._s(_vm.$t("PRICES_PARTNER"))),
                              ]),
                              _c("PartnerPrices", {
                                attrs: { prices: _vm.payload.prices },
                              }),
                              _c("hr", { staticClass: "p-0 m-0 mt-4 mb-2" }),
                              _c("b-button", { on: { click: _vm.gotoStep2 } }, [
                                _vm._v(_vm._s(_vm.$t("BACK"))),
                              ]),
                              _c("b-button", { on: { click: _vm.gotoStep4 } }, [
                                _vm._v(_vm._s(_vm.$t("NEXT"))),
                              ]),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.step == 4
                        ? _c(
                            "div",
                            [
                              _c("div", { staticClass: "info-header" }, [
                                _vm._v(_vm._s(_vm.$t("PRICES"))),
                              ]),
                              _c("Prices", {
                                attrs: { prices: _vm.payload.prices },
                              }),
                              _c("hr", { staticClass: "p-0 m-0 mt-2 mb-2" }),
                              _c("b-button", { on: { click: _vm.gotoStep2 } }, [
                                _vm._v(_vm._s(_vm.$t("BACK"))),
                              ]),
                              _c(
                                "b-button",
                                {
                                  staticClass: "btn-fill",
                                  attrs: {
                                    disabled: !_vm.validEmail,
                                    variant: "primary",
                                  },
                                  on: { click: _vm.createNode },
                                },
                                [_vm._v(_vm._s(_vm.$t("SUBMIT")))]
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    [
      _c("div", { staticClass: "info-header mt-2" }, [
        _vm._v(_vm._s(_vm.$t("LICENSE.EC"))),
      ]),
      _c(
        "b-form-group",
        { attrs: { "label-cols-md": "4", label: _vm.$t("LICENSE.LEVELS.N1") } },
        [
          _c("b-form-input", {
            staticClass: "w-150",
            attrs: { trim: "", type: "number", number: _vm.return_number },
            model: {
              value: _vm.prices.n1,
              callback: function ($$v) {
                _vm.$set(_vm.prices, "n1", $$v)
              },
              expression: "prices.n1",
            },
          }),
          _vm._v(" " + _vm._s(_vm.prices.currency) + "\n    "),
        ],
        1
      ),
      _c(
        "b-form-group",
        { attrs: { "label-cols-md": "4", label: _vm.$t("LICENSE.LEVELS.N2") } },
        [
          _c("b-form-input", {
            staticClass: "w-150",
            attrs: { trim: "", type: "number", number: _vm.return_number },
            model: {
              value: _vm.prices.n2,
              callback: function ($$v) {
                _vm.$set(_vm.prices, "n2", $$v)
              },
              expression: "prices.n2",
            },
          }),
          _vm._v(" " + _vm._s(_vm.prices.currency) + "\n    "),
        ],
        1
      ),
      _c(
        "b-form-group",
        { attrs: { "label-cols-md": "4", label: _vm.$t("LICENSE.LEVELS.N3") } },
        [
          _c("b-form-input", {
            staticClass: "w-150",
            attrs: { trim: "", type: "number", number: _vm.return_number },
            model: {
              value: _vm.prices.n3,
              callback: function ($$v) {
                _vm.$set(_vm.prices, "n3", $$v)
              },
              expression: "prices.n3",
            },
          }),
          _vm._v(" " + _vm._s(_vm.prices.currency) + "\n    "),
        ],
        1
      ),
      _c(
        "b-form-group",
        { attrs: { "label-cols-md": "4", label: _vm.$t("LICENSE.LEVELS.N4") } },
        [
          _c("b-form-input", {
            staticClass: "w-150",
            attrs: { trim: "", type: "number", number: _vm.return_number },
            model: {
              value: _vm.prices.n4,
              callback: function ($$v) {
                _vm.$set(_vm.prices, "n4", $$v)
              },
              expression: "prices.n4",
            },
          }),
          _vm._v(" " + _vm._s(_vm.prices.currency) + "\n    "),
        ],
        1
      ),
      _c(
        "b-form-group",
        { attrs: { "label-cols-md": "4", label: _vm.$t("LICENSE.LEVELS.N5") } },
        [
          _c("b-form-input", {
            staticClass: "w-150",
            attrs: { trim: "", type: "number", number: _vm.return_number },
            model: {
              value: _vm.prices.n5,
              callback: function ($$v) {
                _vm.$set(_vm.prices, "n5", $$v)
              },
              expression: "prices.n5",
            },
          }),
          _vm._v(" " + _vm._s(_vm.prices.currency) + "\n    "),
        ],
        1
      ),
      _c(
        "b-form-group",
        { attrs: { "label-cols-md": "4", label: _vm.$t("AS_SERVICE") } },
        [
          _c("b-form-input", {
            staticClass: "w-150",
            attrs: { trim: "", type: "number", number: _vm.return_number },
            model: {
              value: _vm.prices.service,
              callback: function ($$v) {
                _vm.$set(_vm.prices, "service", $$v)
              },
              expression: "prices.service",
            },
          }),
          _vm._v(" " + _vm._s(_vm.prices.currency) + "\n    "),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <section>

    
    <div class="info-header mt-2">{{ $t("LICENSE.EC") }}</div>

      <b-form-group label-cols-md="4" :label="$t('LICENSE.LEVELS.N1')">
         <b-form-input trim v-model="prices.n1" type="number" class="w-150" :number="return_number"></b-form-input> {{prices.currency}}
      </b-form-group>

      <b-form-group  label-cols-md="4" :label="$t('LICENSE.LEVELS.N2')">
        <b-form-input trim v-model="prices.n2" type="number" class="w-150" :number="return_number"></b-form-input> {{prices.currency}}
      </b-form-group>

      <b-form-group  label-cols-md="4" :label="$t('LICENSE.LEVELS.N3')">
        <b-form-input trim v-model="prices.n3" type="number" class="w-150" :number="return_number"></b-form-input> {{prices.currency}}
      </b-form-group>

      <b-form-group  label-cols-md="4" :label="$t('LICENSE.LEVELS.N4')">
        <b-form-input trim v-model="prices.n4" type="number" class="w-150" :number="return_number"></b-form-input> {{prices.currency}}
      </b-form-group>

      <b-form-group  label-cols-md="4" :label="$t('LICENSE.LEVELS.N5')">
         <b-form-input trim v-model="prices.n5" type="number" class="w-150" :number="return_number"></b-form-input> {{prices.currency}}
      </b-form-group>

      <b-form-group  label-cols-md="4" :label="$t('AS_SERVICE')">
         <b-form-input trim v-model="prices.service" type="number" class="w-150" :number="return_number"></b-form-input> {{prices.currency}}
      </b-form-group>

  </section>
</template>
<script>
export default {
  props: ["prices"],
  data() {
    return {
      return_number: true
    };
  },
  methods: {},
  computed: {},
};
</script>
<style></style>
